const UserTypeEnum = {
    ADMIN: 'admin',
    EMPLOYEE: 'employee',
    ATHLETE: 'athlete'
};

const formatUserType = (userType) => {
    const formattedUserType = {
        [UserTypeEnum.ADMIN]: 'Administrador',
        [UserTypeEnum.EMPLOYEE]: 'Funcionário',
        [UserTypeEnum.ATHLETE]: 'Atleta'
    };

    return formattedUserType[userType] || 'Unknown';
}

export default { UserTypeEnum, formatUserType };