import { Key, useEffect, useLayoutEffect, useRef, useState } from "react";
import { favoriteImage, getFavoriteImages } from "../../../../utils/api";
import dayjs from "dayjs";
import sortSessions from "../../../../utils/sort-sessions";
import StarIcon from '@mui/icons-material/Star'; // Ícone de estrela preenchida
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, IconButton, ImageList, ImageListItem, ListSubheader, Modal, useMediaQuery, useTheme } from "@mui/material";
import GallerySkeleton from "../GallerySkeleton";
import { isVideo } from "../../../../utils/checkFileFormat";

const FavoritesGallery: React.FC = () => {
    const [classifiedImages, setClassifiedImages] = useState<any>({})
    const [sortedDates, setSortedDates] = useState<any>([])
    const [previewMedia, setPreviewMedia] = useState<{ url: string; type: "image" | "video" } | null>(null); // Estado para o preview
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const theme = useTheme()
    const [rowHeight, setRowHeight] = useState<number>(200); // Estado para o rowHeight
    const columnNumber = mdUp ? 4 : 2
    const imageListRef = useRef<HTMLUListElement>(null); // Ref para o contêiner do ImageList

    useEffect(() => {
        getFavoriteImages()
            .then(({ data }) => {
                const newClassifiedImages: any = {}

                data.images.forEach((image: any) => {
                    const sessionMonth = dayjs(image["session_time"], "DD/MM/YYYY - HH:mm").format("MMMM/YYYY")
                    const filename = image.image_url.split("?")[0].split("/").toReversed()[0]

                    if (sessionMonth in newClassifiedImages) {
                        newClassifiedImages[sessionMonth].push({ ...image, isVideo: isVideo(filename) })
                    } else {
                        newClassifiedImages[sessionMonth] = [{ ...image, isVideo: isVideo(filename) }]
                    }
                })

                Object.values(newClassifiedImages).map(monthImages => sortSessions(monthImages))

                const sortedDates = Object.keys(newClassifiedImages).sort((a, b) =>
                    dayjs(a, "MMMM/YYYY").isBefore(dayjs(b, "MMMM/YYYY")) ? 1 : -1
                );

                setClassifiedImages(newClassifiedImages)
                setSortedDates(sortedDates)
            })
            .catch(err => console.log(err))
    }, [])

    const calculateRowHeight = () => {
        if (imageListRef.current) {
            const containerWidth = imageListRef.current.clientWidth; // Largura do contêiner
            const itemWidth = containerWidth / columnNumber; // Largura de cada ImageListItem
            setRowHeight(itemWidth * 1.54); // 154% da largura
        }
    };

    function calculateEmptyImages(imagesLength: number) {
        const cols = columnNumber;
        const remainder = imagesLength % cols;

        return remainder === 0 ? 0 : cols - remainder
    }

    const toggleStar = (image_id: string, date: string) => {
        favoriteImage({ image_id, status: false })
            .then(() => {
                const newClassifiedImages = { ...classifiedImages };
                newClassifiedImages[date] = classifiedImages[date].filter((image: any) => image.id !== image_id);

                // Se o array ficou vazio, remove a chave do objeto
                if (newClassifiedImages[date].length === 0) {
                    delete newClassifiedImages[date];
                }

                // Atualiza sortedDates removendo a data se o array ficou vazio
                const newSortedDates = Object.keys(newClassifiedImages).sort((a, b) =>
                    dayjs(a, "MMMM/YYYY").isBefore(dayjs(b, "MMMM/YYYY")) ? 1 : -1
                );

                setClassifiedImages(newClassifiedImages);
                setSortedDates(newSortedDates);
            })
            .catch(err => console.log(err));
    };

    const handleOpenPreview = (url: string, isVideo: boolean) => {
        setPreviewMedia({ url, type: isVideo ? "video" : "image" });
    };

    const handleClosePreview = () => {
        setPreviewMedia(null);
    };

    useLayoutEffect(() => {
        calculateRowHeight(); // Calcula o rowHeight inicial

        window.addEventListener("resize", () => calculateRowHeight); // Recalcula ao redimensionar a janela
        return () => window.removeEventListener("resize", calculateRowHeight); // Limpa o listener
    }, [classifiedImages])

    return (
        <>
            {sortedDates.length > 0 ?
                sortedDates.map((date: any) => (
                    <>
                        <ImageList cols={columnNumber} rowHeight={rowHeight} ref={imageListRef} sx={{ mb: theme.spacing(10) }}>
                            <ImageListItem key="Subheader" cols={columnNumber} sx={{ height: "auto!important", px: 1 }}>
                                <ListSubheader component="div" sx={{ paddingLeft: 0, paddingBottom: 1, color: theme.palette.grey[700], textTransform: "capitalize", fontSize: "18px" }}>{date}</ListSubheader>
                            </ImageListItem>
                            {classifiedImages[date].map((image: any, index: Key | null | undefined) => (
                                <ImageListItem
                                    key={index}
                                    sx={{
                                        overflow: "hidden",
                                        position: "relative",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            overflow: "hidden",
                                            cursor: "pointer",
                                            "& img, & video": {
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                transition: "transform 0.3s ease-in-out",
                                            },
                                            "&:hover img, &:hover video": {
                                                transform: "scale(1.1)",
                                            },
                                        }}
                                        onClick={() => handleOpenPreview(image.image_url, image.isVideo)} // Abre o preview ao clicar
                                    >
                                        <img
                                            src={image.thumb_url} // Usa a thumb estática
                                            alt={`Imagem ${image.id}`}
                                            loading="lazy"
                                        />

                                        <IconButton
                                            sx={{
                                                position: "absolute",
                                                right: 4,
                                                top: 4,
                                                borderRadius: "4px",
                                                padding: "4px",
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation(); // Impede a propagação do evento de clique
                                                toggleStar(image.id, date); // Alterna o estado da estrela
                                            }}
                                        >

                                            <StarIcon fontSize="medium" sx={{ color: "gold" }} />

                                        </IconButton>
                                    </Box>

                                    {image.isVideo ?
                                        <IconButton
                                            sx={{
                                                position: "absolute",
                                                left: "calc(50% - 20px)",
                                                top: "calc(50% - 20px)",
                                                backgroundColor: "rgb(0 0 0 / 60%)",
                                                border: "1px solid white",
                                                "&:hover": {
                                                    backgroundColor: "rgb(0 0 0 / 40%)",
                                                }
                                            }}
                                            onClick={() => handleOpenPreview(image.image_url, image.isVideo)} // Abre o preview ao clicar
                                        >
                                            <PlayArrowIcon fontSize="medium" sx={{ margin: "-1px", color: theme.palette.background.paper }} />
                                        </IconButton>
                                        : null
                                    }
                                </ImageListItem>
                            ))}
                            {Array.from({ length: calculateEmptyImages(classifiedImages[date].length) }).map((_, index) => (
                                <ImageListItem key={`empty-${index}`}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList >

                        < Modal
                            open={!!previewMedia} // Abre o modal se previewMedia não for null
                            onClose={handleClosePreview}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: "90%",
                                    maxHeight: "90%",
                                    outline: "none",
                                }}
                            >
                                {previewMedia?.type === "image" ? (
                                    <img
                                        src={previewMedia.url}
                                        alt="Preview"
                                        style={{ width: "100%", height: "auto", maxHeight: "80vh" }}
                                    />
                                ) : (
                                    <video
                                        src={previewMedia?.url}
                                        controls
                                        autoPlay
                                        style={{ width: "100%", height: "auto", maxHeight: "80vh" }}
                                    />
                                )}
                            </Box>
                        </Modal >
                    </>
                ))
                : <GallerySkeleton />}
        </>
    );
};

export default FavoritesGallery;
