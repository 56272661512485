export default function sortFiles(a, b) {
    const extensaoA = a.filename.split('.').pop().toLowerCase(); // Extrai a extensão do arquivo A
    const extensaoB = b.filename.split('.').pop().toLowerCase(); // Extrai a extensão do arquivo B

    // Define a ordem dos grupos: imagens primeiro (0), vídeos depois (1)
    const grupoA = extensaoA === 'jpg' ? 0 : 1;
    const grupoB = extensaoB === 'jpg' ? 0 : 1;

    // Se os arquivos estão no mesmo grupo, ordena alfabeticamente pelo filename
    if (grupoA === grupoB) {
        return a.filename.localeCompare(b.filename);
    }

    // Caso contrário, ordena pelo grupo
    return grupoA - grupoB;
}