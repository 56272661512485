import MainRouter from './router'
import locale from 'antd/locale/pt_BR';
import dayjs from 'dayjs';
import { ConfigProvider } from "antd"

import 'dayjs/locale/pt-br';
import './reset.scss';

dayjs.locale('pt-br');

const BackofficeApp = () => {
    return (
        <ConfigProvider locale={locale}>
            <MainRouter />
        </ConfigProvider>
    )
}

export default BackofficeApp