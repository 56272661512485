import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Card, DatePicker, Divider, Flex, Empty, Button, Image, Checkbox, Typography, Select, notification } from 'antd'
import { UserAddOutlined } from '@ant-design/icons';
import { moveResultImage, setShowResultImage, saveSession, updateSession } from '../../../../utils/api'
import { downloadAndZipFiles } from '../../../../utils/download-files';
import MoveSelectedTo from '../MoveSelectedTo';
import GalerySkeleton from '../GalerySkeleton';
import dayjs from 'dayjs'
import range from '../../../../utils/range';

const waveSelectOptions = [
    { value: 'Níveis 1 e 2 - Combo', label: 'Níveis 1 e 2 - Combo' },
    { value: 'Níveis 3 e 4 - Direita', label: 'Níveis 3 e 4 - Direita' },
    { value: 'Níveis 3 e 4 - Esquerda', label: 'Níveis 3 e 4 - Esquerda' },
    { value: 'Nível 5 - Direita', label: 'Nível 5 - Direita' },
    { value: 'Nível 5 - Esquerda', label: 'Nível 5 - Esquerda' },
    { value: 'Nível 6 - Direita', label: 'Nível 6 - Direita' },
    { value: 'Nível 6 - Esquerda', label: 'Nível 6 - Esquerda' },
    { value: 'Nível 7 - Direita', label: 'Nível 7 - Direita' },
    { value: 'Nível 7 - Esquerda', label: 'Nível 7 - Esquerda' },
    { value: 'Nível 8 - Direita', label: 'Nível 8 - Direita' },
    { value: 'Nível 8 - Esquerda', label: 'Nível 8 - Esquerda' },
    { value: 'Marola', label: 'Marola' },
]

const ProcessingResults = ({ requestId, requesterEmail, level, time, results, setResults, refreshResults, isLoading, setIsLoading }) => {
    const [activeTabKey, setActiveTabKey] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [processingFoldersOptions, setProcessingFolderOptions] = useState([])
    const [hasSelectedFile, setHasSelectedFile] = useState(false)
    const [selectedWave, setSelectedWave] = useState(level)
    const [sessionTime, setSessionTime] = useState(time ? { datetime: dayjs(time, "DD/MM/YYYY - HH:mm"), formattedDate: time } : null)
    const [isDownloading, setIsDownloading] = useState(false);
    const isFirstTime = !(level && time)

    const toggleFileDisabled = (image_id, status, folder) => {
        setShowResultImage({ image_id, status: !status }).then(() => {
            const newResults = [...results]

            const foundFolder = newResults.find(result => result.folder === folder)

            const foundFile = foundFolder.files.find(result => result.id === image_id)

            foundFile.show_image = !status

            setResults(newResults)
        })
            .catch(err => console.log(err))
    }

    const toggleFileSelected = (file, folder) => {
        const newResults = [...results]

        const foundFolder = newResults.find(result => result.folder === folder)

        const foundFile = foundFolder.files.find(result => result.file === file)

        foundFile.selected = !foundFile.selected

        setResults(newResults)
    }

    const resetSelectedState = (folder) => {
        const newResults = [...results]

        const foundFolder = newResults.find(result => result.folder === folder)

        foundFolder.files.forEach(file => { file.selected = false })
    }

    const onTabChange = (key) => {
        resetSelectedState(activeTabKey)
        setActiveTabKey(key);
    };

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
            placement: 'bottomRight'
        });
    };

    const handleDownloadFiles = async () => {
        setIsDownloading(true);

        const files_urls = hasSelectedFile
            ? results.find(result => result.folder === activeTabKey).files.filter(file => !!file.selected).map(file => file.file)
            : results.find(result => result.folder === activeTabKey).files.map(file => file.file);

        await downloadAndZipFiles(files_urls, activeTabKey);

        setIsDownloading(false);
    };

    const handleFolderChange = (selectedOption) => {
        const image_ids = results.find(result => result.folder === activeTabKey).files.filter(file => !!file.selected).map(file => file.id)
        const payload = { image_ids, destination_id: selectedOption.value }

        setActiveTabKey(selectedOption.key)
        setIsLoading(true)

        moveResultImage(payload).then(() => {
            refreshResults()
        })
            .catch(err => console.log(err))
            .finally(() => { setIsLoading(false) })

    }

    const handleActivateSession = (id, is_active) => {
        updateSession(id, { is_active }).then(() => refreshResults()).catch(err => console.log(err))
    }

    const handleDatePickerChange = (value, dateString) => {
        setSessionTime({ datetime: dayjs(value), formattedDate: dateString })
    }

    const onWaveSelectChange = (value, selectedOption) => {
        setSelectedWave(value)
    }

    const saveResults = () => {
        const payload = {
            "s3_folder": requestId,
            "level": selectedWave,
            "session_time": sessionTime.formattedDate,
            "available_to_user": true,
        }

        saveSession(payload)
            .then(() => {
                openNotification('success', 'Sucesso', 'A sessão foi enviada para os clientes!')

                if (isFirstTime) {
                    refreshResults()
                }
            })
            .catch((err) => console.log(err))
    }

    useLayoutEffect(() => {
        if (!activeTabKey) {
            setActiveTabKey(results[0].folder);
        }
    }, [results])

    useEffect(() => {
        setProcessingFolderOptions(results.map(result => ({ key: result.folder, value: result.user_id ?? result.folder, label: result.folder_name, disabled: result.folder === activeTabKey })))

        if (activeTabKey) {
            setHasSelectedFile(results.find(result => result.folder === activeTabKey).files.some(file => !!file.selected))
        }
    }, [results])

    useEffect(() => {
        resetSelectedState(activeTabKey)
        setHasSelectedFile(false)
    }, [activeTabKey])

    const ResultCard = ({ files, folder }) => {
        const isVideoFile = (fileName) => {
            const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
            const extension = fileName.split('.').pop().split('?')[0].toLowerCase();
            return videoExtensions.includes(extension);
        };

        return (
            <>
                {isLoading
                    ? <GalerySkeleton />
                    : files.map(({ id, show_image, file, thumb, selected }) => (
                        <Flex
                            key={id}
                            style={{ width: '100px', position: "relative", justifyContent: "space-between" }}
                            vertical
                        >
                            {!show_image ? (
                                <>
                                    <Image width="100%" src={thumb} style={{ opacity: ".2" }} preview={false} />
                                    <Button style={{ marginTop: "4px" }} onClick={() => toggleFileDisabled(id, show_image, folder)}>
                                        Incluir
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {isVideoFile(file) ? (
                                        <Image
                                            width="100%"
                                            src={thumb}
                                            preview={{
                                                destroyOnClose: true,
                                                imageRender: () => (
                                                    <>
                                                        <video
                                                            muted
                                                            width="90%"
                                                            controls
                                                            src={file}
                                                        />
                                                    </>
                                                ),
                                                toolbarRender: () => null,
                                            }}
                                        />
                                    ) : (
                                        <Image
                                            width="100%"
                                            src={thumb}
                                            preview={{ src: file }}
                                        />
                                    )}
                                    <Checkbox
                                        style={{
                                            position: "absolute",
                                            bottom: "40px",
                                            right: "5px"
                                        }}
                                        checked={selected}
                                        onChange={() => toggleFileSelected(file, folder)}
                                    />
                                    <Button style={{ marginTop: "4px" }} onClick={() => toggleFileDisabled(id, show_image, folder)}>
                                        Vetar
                                    </Button>
                                </>
                            )}
                        </Flex>
                    ))
                }
            </>
        );
    };


    const [tabList, contentList] = results.reduce((acc, { folder, folder_name, files, is_active, user_id, session_id }) => {
        const createAthleteVariables = `/backoffice/athletes/register?sessionId=${requestId}&folder=${folder}`
        const allowedCreateAthlete = /^nao-reconhecidos-\d+$/.test(folder);

        acc[0].push({
            key: folder,
            label: (
              <span>
                {folder_name}{" "}
                {is_active ? "🟢" : user_id ? "🔴" : ""}
                {allowedCreateAthlete && (
                  <Button
                    size="small"
                    href={createAthleteVariables}
                    style={{ marginLeft: 8 }}
                    icon={<UserAddOutlined />}
                  />
                )}
              </span>
            ),
          });

        acc[1][folder] = (
            <Flex vertical gap="small">
                {files.length !== 0 ? (
                    <>
                        <Button
                            type="primary"
                            onClick={() => handleActivateSession(session_id, !is_active)}
                            style={{ alignSelf: 'flex-start' }}
                            iconPosition="end"
                        >
                            {is_active ? 'Vetar sessão' : 'Ativar sessão'}
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => handleDownloadFiles()}
                            style={{ alignSelf: 'flex-start' }}
                            loading={isDownloading}
                            iconPosition="end"
                        >
                            {isDownloading ? 'Compactando Arquivos' : (hasSelectedFile ? 'Baixar Selecionadas' : 'Baixar Tudo')}
                        </Button>
                        <Flex gap="middle" wrap="wrap" style={{ maxHeight: "400px", overflowX: "auto" }}>
                            <ResultCard files={files} folder={folder} />
                        </Flex>
                    </>

                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Flex>
        );

        return acc;
    }, [[], {}]);

    return (
        <>
            <Flex vertical gap="middle" style={{ paddingLeft: "24px" }}>
                <Flex align="center">
                    <Typography.Title level={5} style={{ margin: 0, marginRight: 16 }}>Data e hora: </Typography.Title>
                    <DatePicker
                        showTime={{ format: 'HH:mm', disabledTime: () => ({ disabledMinutes: () => range(0, 60, 1, [0, 30]) }), hideDisabledOptions: true, }}
                        format="DD/MM/YYYY - HH:mm"
                        maxDate={dayjs()}
                        onChange={handleDatePickerChange}
                        value={sessionTime ? dayjs(sessionTime.datetime) : sessionTime}

                    />
                </Flex>
                <Flex align="center">
                    <Typography.Title level={5} style={{ margin: 0, marginRight: 16 }}>Tipo de onda: </Typography.Title>
                    <Select
                        placeholder="Selecione o tipo de onda"
                        options={waveSelectOptions}
                        onChange={onWaveSelectChange}
                        value={selectedWave}
                    />
                </Flex>
            </Flex>
            <Divider />
            <Card style={{
                width: '100%',
            }}
                bordered={false}
                tabList={tabList}
                activeTabKey={activeTabKey}
                onTabChange={onTabChange}
            >
                {contentList[activeTabKey]}
            </Card >
            {hasSelectedFile ?
                <MoveSelectedTo options={processingFoldersOptions} handleChange={handleFolderChange} />
                : null
            }
            <Divider />
            <Flex justify="space-between" align="center">
                <Typography.Text>Link do resultado também enviado para: <Typography.Text strong>{requesterEmail}</Typography.Text></Typography.Text>
                {isFirstTime ?
                    sessionTime && selectedWave ?
                        <Button type="primary" onClick={saveResults}>Enviar para o cliente</Button>
                        : null

                    :
                    <Button type="primary" onClick={saveResults}>Salvar alterações</Button>
                }
            </Flex>
            {contextHolder}
        </>
    )
}

export default ProcessingResults