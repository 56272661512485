import { Avatar, Box, Button, Divider, Grid2 as Grid, IconButton, Stack, Typography, useTheme } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { getData, removeData } from "../../../../utils/local-storage";
import { logout } from "../../../../utils/api";

interface MainLayoutProps {
    children: React.ReactNode,
    hasBackButton?: boolean
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, hasBackButton = true }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const authData = getData('auth_data');

    const handleGoBack = () => {
        if (window.history.length > 1) {
            navigate(-1); // Volta à página anterior
        } else {
            navigate("/"); // Redireciona para a página inicial
        }
    };

    const handleLogout = async () => {
        try {
            const response = await logout()
            removeData('auth_data');
            navigate('/')
        } catch (e) {
            console.log('Não foi possível fazer logout.')
            window.location.reload()
        }
    }

    return (
        <Grid container alignItems="center" justifyContent="center" size={12} height="100vh" sx={{
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0',
                height: '0.4em',
                background: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent'
            },
            scrollbarWidth: 'none',
            scrollbarColor: 'transparent transparent'
        }}>
            <Grid container direction="column" flexWrap="nowrap" size={{ xs: 12, md: 6 }} height="100%" >
                <Grid width="100%" height="100px" py={8} px={2}>
                    <Stack direction="row" sx={{ position: "relative" }} justifyContent="space-between">
                        {hasBackButton ?
                            <IconButton sx={{ position: "absolute", top: "-50px", left: 0, padding: 0 }} onClick={handleGoBack}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            : null}
                        <Stack direction="row" alignItems="center">
                            <Avatar src={authData?.user_image} />
                            < Box mx={{ xs: 1, md: 2 }} />
                            <Typography variant="h4" sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}>{authData?.name}</Typography>
                        </Stack>
                        <Button variant="text" size="small" startIcon={<LogoutIcon />} onClick={handleLogout} sx={{ borderColor: theme.palette.grey[400], color: theme.palette.grey[600], position: "absolute", top: "-50px", right: 0, padding: 0 }}>Logout</Button>
                    </Stack>
                </Grid>
                <Grid >
                    <Divider />
                    {children}
                </Grid>
            </Grid>
        </Grid >
    )
}

export default MainLayout