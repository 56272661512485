import React, { useEffect, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { validateAccessToken } from "../../../../utils/api";
import { isAccessTokenValid } from "../../../../utils/api-config";
import { getData } from "../../../../utils/local-storage";
import UserType from "../../enums/userTypeEnum";

type AllowedUserType = typeof UserType.UserTypeEnum[keyof typeof UserType.UserTypeEnum];

interface AuthWrapperProps {
    children: ReactNode;
    requiredUserType: AllowedUserType[];
}

const PrivateRoute: React.FC<AuthWrapperProps> = ({ children, requiredUserType }) => {
    const authData = getData("auth_data");
    const isAuthorized = authData && authData.user_type && requiredUserType.includes(authData.user_type);
    const isAthlete = authData && authData.user_type && authData.user_type === UserType.UserTypeEnum.ATHLETE;
    const isTokenAuthorized = isAccessTokenValid(authData?.access_token);

    useEffect(() => {
        if (isAuthorized && isTokenAuthorized) {
            const validate = async () => {
                await validateAccessToken();
            };
            validate();
        }
    }, [isAuthorized, isTokenAuthorized]);

    if (isAuthorized && isTokenAuthorized) {
        return <>{children}</>;
    } else {
        return <Navigate to={isTokenAuthorized ? (isAthlete ? "/client" : "/backoffice") : "/"} />;
    }
};

export default PrivateRoute;
