import axiosInstance from './api-config'

export const validateAccessToken = async () => {
    return await axiosInstance.get(`/jwt-healthcheck`)
}

export const sendInputFiles = (data) => {
    return axiosInstance.post(`/face-recognizer/create`, data)
}

export const createInputUser = (data) => {
    return axiosInstance.post(`/input-users`, data)
}

export const updateInputUser = (inputUserId, data) => {
    return axiosInstance.put(`/input-users/${inputUserId}`, data)
}

export const getInputUser = (inputUserId) => {
    return axiosInstance.get(`/input-users/${inputUserId}`)
}

export const deleteInputUser = (inputUserId) => {
    return axiosInstance.delete(`/input-users/${inputUserId}`)
}

export const sendAthleteAccountNotification = (inputUserId, payload) => {
    return axiosInstance.post(`/input-users/${inputUserId}/send-account-notification`, payload)
}

export const getAthleteSessions = async () => {
    return await axiosInstance.get(`/athlete/sessions`)
}

export const getAthleteSessionImages = async (id) => {
    return await axiosInstance.get(`/athlete/sessions/${id}`)
}

export const getFavoriteImages = async (payload) => {
    return await axiosInstance.get(`/athlete/favorite-images`)
}

export const favoriteImage = async (payload) => {
    return await axiosInstance.post(`/athlete/favorite-image`, payload)
}

export const getInputUserList = (data) => {
    const queryParams = {};

    if (data.search && data.search.trim() !== '') {
        queryParams.search = data.search;
    }
    if (data.page) {
        queryParams.page = data.page;
    }
    if (data.perPage) {
        queryParams.perPage = data.perPage;
    }

    return axiosInstance.get('/input-users', {
        params: queryParams
    });
}

export const createSystemUser = (data) => {
    return axiosInstance.post(`/users`, data)
}

export const updateSystemUser = (userId, data) => {
    return axiosInstance.put(`/users/${userId}`, data)
}

export const getSystemUser = (userId) => {
    return axiosInstance.get(`/users/${userId}`)
}

export const deleteSystemUser = (userId) => {
    return axiosInstance.delete(`/users/${userId}`)
}

export const getSystemUserList = (data) => {
    const queryParams = {};

    if (data.search && data.search.trim() !== '') {
        queryParams.search = data.search;
    }
    if (data.page) {
        queryParams.page = data.page;
    }
    if (data.perPage) {
        queryParams.perPage = data.perPage;
    }

    return axiosInstance.get('/users', {
        params: queryParams
    });
}

export const getProcessingStatus = (hash) => {
    return axiosInstance.get(`/face-recognizer/${hash}`)
}

export const getProcessingResults = (hash) => {
    return axiosInstance.get(`/face-recognizer/${hash}/results`)
}

export const getResultZipUrl = (hash) => {
    return axiosInstance.get(`/face-recognizer/${hash}/compressed`)
}

export const setShowResultImage = (payload) => {
    return axiosInstance.post(`/face-recognizer/show-result-image`, payload)
}

export const moveResultImage = (payload) => {
    return axiosInstance.post(`/face-recognizer/move-result-image`, payload)
}

export const saveSession = (payload) => {
    return axiosInstance.post(`/face-recognizer/save-recognition`, payload)
}

export const updateSession = (id, payload) => {
    return axiosInstance.put(`/user_sessions/${id}`, payload)
}

export const login = (email, password) => {
    const payload = {
        email,
        password
    }

    return axiosInstance.post(`/login`, payload)
}

export const logout = () => {
    return axiosInstance.post(`/logout`)
}

export const forgotPassword = (email) => {
    const payload = { email }

    return axiosInstance.post(`/forgot-password`, payload)
}

export const newPassword = (payload) => {
    return axiosInstance.post(`/new-password`, payload)
}

export const getSessionsList = (data) => {
    const queryParams = {};

    if (data.search && data.search.trim() !== '') {
        queryParams.search = data.search;
    }
    if (data.page) {
        queryParams.page = data.page;
    }
    if (data.perPage) {
        queryParams.perPage = data.perPage;
    }

    return axiosInstance.get('/face-recognizer', {
        params: queryParams
    });
}