import React, { useState, useEffect } from 'react'
import { Layout, Card, Flex, Typography, Divider } from 'antd'
import Header from '../../components/Header'
import { useParams } from "react-router-dom";
import { getProcessingStatus, getProcessingResults } from '../../../../utils/api'
import ProcessingProgress from '../../components/ProcessingProgress';
import ProcessingResults from '../../components/ProcessingResults'
import sortFiles from '../../../../utils/sort-files';

const ResultsPage = () => {
    const [percent, setPercent] = useState(0)
    const [status, setStatus] = useState()
    const [requesterEmail, setRequesterEmail] = useState()
    const [sessionTime, setSessionTime] = useState()
    const [level, setLevel] = useState("")
    const { sessionId } = useParams();
    const [processingResults, setProcessingResults] = useState([{
        "files": [
            {
                "file": "",
                "thumb": "",
                "selected": false
            }
        ],
        "folder": ""
    }])
    const [isLoading, setIsLoading] = useState(false)

    const fetchData = () => {
        setIsLoading(true)

        getProcessingResults(sessionId).then(({ data }) => {
            const results = data.result.map(value => {
                const notMovedImages = [...value.files.filter((image) => !image.is_moved)]
                const movedImages = [...value.files.filter((image) => image.is_moved)]

                const sortedNotMovedImages = notMovedImages.map(file => ({ ...file, ...{ selected: false, filename: file.file.split("?")[0].split("/").toReversed()[0] } })).sort(sortFiles)
                const sortedMovedImages = movedImages.map(file => ({ ...file, ...{ selected: false, filename: file.file.split("?")[0].split("/").toReversed()[0] } })).sort(sortFiles)

                return { ...value, files: [...sortedNotMovedImages, ...sortedMovedImages] }
            })

            setProcessingResults(results)
        })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const statusPooling = async () => {
        const { data: { task_status, progress_percentage, requester_email, level, session_time } } = await getProcessingStatus(sessionId)

        setStatus(task_status)
        setPercent(progress_percentage)
        setRequesterEmail(requester_email)
        setLevel(level)
        setSessionTime(session_time)

        if (task_status === "processed") {
            fetchData()
        }

        if (task_status !== 'failed' && task_status !== 'processed') {
            return setTimeout(() => {
                statusPooling()
            }, 2000)
        }
    }

    useEffect(() => {
        statusPooling()
    }, [])

    return (
        <Layout style={{ width: '100vw', minHeight: '100vh', backgroundColor: '#f5f5f5', padding: '24px', justifyContent: 'center' }}>
            <Header />
            <Card bordered={false} className="card" style={{ width: '100%' }}>
                <Flex className="title-wrapper" justify="center">
                    <Typography.Title level={2}>{status === 'processed' ? "Dados da Sessão" : "Resultados"}</Typography.Title>
                </Flex>
                {status === 'processed' ?
                    <ProcessingResults requestId={sessionId} level={level} time={sessionTime} requesterEmail={requesterEmail} results={processingResults} setResults={setProcessingResults} refreshResults={statusPooling} isLoading={isLoading} setIsLoading={setIsLoading} />
                    :
                    <>
                        <ProcessingProgress percent={percent} status={status} requesterEmail={requesterEmail} />
                        <Divider />
                        <Typography.Text>Após o processamento, o link do resultado será enviado para: <Typography.Text strong>{requesterEmail}</Typography.Text></Typography.Text>
                    </>
                }
            </Card>
        </Layout>
    )
}

export default ResultsPage