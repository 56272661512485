import {
    Routes,
    Route
} from "react-router-dom";
import MainPage from "./pages/Main";
import SessionPage from "./pages/Session";
import PrivateRoute from "./components/PrivateRoute";

const MainRouter = () => {
    return (
        <Routes>
            <Route path="session/:sessionId" element={<PrivateRoute requiredUserType={['athlete']}><SessionPage /></PrivateRoute>} />
            <Route index element={<PrivateRoute requiredUserType={['athlete']}><MainPage /></PrivateRoute>} />
        </Routes>
    )
}

export default MainRouter;