import { Box, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";

const GallerySkeleton = () => {
    const theme = useTheme();
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const columnNumber = mdUp ? 3 : 2;

    return (
        <Box sx={{ width: "100%" }} mt={4}>
            {/* Cabeçalho do Skeleton */}
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "flex-start", sm: "space-between" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                sx={{ marginBottom: theme.spacing(4) }}
            >
                <Skeleton variant="text" width="40%" height={30} />
            </Stack>

            {/* Grid de imagens do Skeleton */}
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${columnNumber}, 1fr)`,
                    gap: 2,
                }}
            >
                {Array.from({ length: 12 }).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="rectangular"
                        sx={{
                            width: "100%",
                            height: 200, // Altura fixa para simular o rowHeight
                            borderRadius: 1,
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default GallerySkeleton;