import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useFilePreview from '../../hooks/useFilePreview';
import { getData } from '../../../../utils/local-storage';

const { Dragger } = Upload;

const DragAndDrop = ({ addFile, removeFile, sessionId, checkUploadingDone }) => {
  const [handlePreview, previewContent] = useFilePreview();

  const handleChange = ({ fileList }) => {
    addFile(fileList);
    checkUploadingDone(fileList)
  };

  return (
    <>
      <Dragger
        action={process.env.REACT_APP_API_BASE_URL + '/face-recognizer/upload'}
        headers={{ 'Authorization': `Bearer ${getData('auth_data').access_token}` }}
        data={{ session_id: sessionId }}
        multiple={true}
        onRemove={removeFile}
        showUploadList={{ showRemoveIcon: false }}
        listType="text"
        onChange={handleChange}
        onPreview={handlePreview}
        accept="image/*,.heic,video/*,.mov,.zip,.rar"
        style={{ marginBottom: '10px' }}
      >
        <p className="ant-upload-drag-icon">
          <PlusOutlined />
        </p>
        <p className="ant-upload-text">
          Clique nesta área ou arraste os arquivos para fazer upload
        </p>
      </Dragger>
      {previewContent}
    </>
  );
};

export default DragAndDrop;
