import dayjs from 'dayjs'

export default function sortSessions(data) {
    data.sort((a, b) => {
        const dateA = dayjs(a.session_time, 'DD/MM/YYYY - HH:mm');
        const dateB = dayjs(b.session_time, 'DD/MM/YYYY - HH:mm');
        return dateA.isAfter(dateB) ? -1 : 1; // Ordena do mais antigo para o mais recente
    });

    return data;
}