import React, { useState, useEffect } from "react";
import { Layout, Card, Button, notification, Divider, Input, Avatar, List, Popconfirm } from "antd";
import { EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import Header from "../../components/Header";
import './sessions.scss'
import { getSessionsList } from "../../../../utils/api";

const ListSessionsPage = () => {
    const [api, contextHolder] = notification.useNotification();
    const [listPage, setListPage] = useState(1);
    const perPage = 10
    const [emptyList, setEmptyList] = useState(false);
    const [searchText, setSearchText] = useState('');
    const ContainerHeight = 400;
    const [data, setData] = useState([]);
    const { Search } = Input;
    const [isSearching, setIsSearching] = useState(false);

    const registerButton = <Button type="primary" href={`/backoffice`}>Nova Sessão <PlusCircleOutlined /></Button>

    const onSearch = async (value, _e, info) => {
        // if (info?.source == 'clear')
        //     return

        setSearchText(value);
        setData([]);
        setEmptyList(false);
        setIsSearching(true);

        try {
            const params = {
                search: value.trim(),
                page: 1,
                perPage: perPage
            };

            const sessionsList = await getSessionsList(params)
            setData(sessionsList.data.analysis);

            if (sessionsList.data.analysis.length < perPage) {
                setEmptyList(true);
                setListPage(1);
                return;
            }

            setListPage(2);
            setIsSearching(false);
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível filtrar a lista de análises.')
        }
    }

    const appendData = async () => {
        if (emptyList || isSearching)
            return;

        try {
            const params = {
                search: searchText.trim(),
                page: listPage,
                perPage: perPage
            };

            const sessionsList = await getSessionsList(params)
            setData(data.concat(sessionsList.data.analysis));

            if (sessionsList.data.analysis.length < perPage) {
                setEmptyList(true);
                return;
            }

            setListPage(listPage + 1)
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível carregar a lista de análises.')
        }
    };

    useEffect(() => {
        appendData();
    }, []);

    const onScroll = (e) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            appendData();
        }
    };

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
            placement: 'bottomRight'
        });
    };

    // const handleRemoveSystemUser = async (athleteId) => {
    //     try {
    //         const response = await deleteSystemUser(athleteId)

    //         const params = {
    //             search: '',
    //             page: 1,
    //             perPage: perPage
    //         };

    //         const listSystemUsers = await getSessionsList(params)
    //         setData(listSystemUsers.data.users);
    //         openNotification('success', 'Sucesso', 'O usuário foi removido com sucesso.')

    //         if (listSystemUsers.data.users.length < perPage) {
    //             setEmptyList(true);
    //             setListPage(1);
    //             return;
    //         }

    //         setEmptyList(false);
    //         setListPage(2);
    //     } catch (e) {
    //         openNotification('error', 'Erro', 'Não foi possível remover o usuário.')
    //     }
    // };

    return (
        <Layout className="layout">
            <Header />
            <Card bordered={false} title="Sessões" className="card" style={{ width: '80%' }} extra={registerButton}>
                <Search
                    placeholder="Procure por identificador de sessão, nível, data ou horário"
                    allowClear
                    onSearch={onSearch}
                />
                <Divider />
                <List>
                    <VirtualList
                        data={data}
                        height={ContainerHeight}
                        itemHeight={47}
                        itemKey="id"
                        onScroll={onScroll}
                    >
                        {(item) => (
                            <List.Item
                                key={item.email}
                                actions={[
                                    <a style={{ marginLeft: '-100%' }} href={`/backoffice/results/${item.s3_folder}`}><Button title="Ver" icon={<EyeOutlined />}></Button></a>,
                                    // <Popconfirm
                                    //     title="Remover Usuário"
                                    //     description="Você tem certeza que deseja remover este usuário?"
                                    //     okText="Sim"
                                    //     cancelText="Não"
                                    //     onConfirm={(e) => handleRemoveSystemUser(item.id)}
                                    //     icon={
                                    //         <QuestionCircleOutlined
                                    //             style={{
                                    //                 color: 'red',
                                    //             }}
                                    //         />
                                    //     }
                                    // >
                                    //     <Button icon={<DeleteOutlined />} danger></Button>
                                    // </Popconfirm>
                                ]}
                            >
                                <List.Item.Meta
                                    // avatar={<Avatar src={item.user_type == 'admin' ? AdminImage : EmployeeImage} />}
                                    title={<a href={`/backoffice/results/${item.s3_folder}`}>{item.s3_folder}</a>}
                                    description={
                                        <div>
                                            {item.session_time ?? 'Sem horário definido'} <br />
                                            {item.level ?? 'Sem nível definido'} <br />
                                            Disponível para o atleta: {item.available_to_user ? 'Sim' : 'Não'}
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    </VirtualList>
                </List>
            </Card>
            {contextHolder}
        </Layout>
    )
}

export default ListSessionsPage