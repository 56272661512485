import { useEffect, useState } from "react";
import SessionList from "../../components/SessionList";
import MainLayout from "../../layouts/Main";
import { getAthleteSessions } from "../../../../utils/api";
import dayjs from 'dayjs'
import GallerySkeleton from "../../components/GallerySkeleton";
import sortSessions from "../../../../utils/sort-sessions";
import { Box, Tab, Tabs } from "@mui/material";
import FavoritesGallery from "../../components/FavoritesGallery";
import FeedGallery from "../../components/FeedGallery";
import { Grid2 as Grid } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}

const MainPage: React.FC = () => {
    const [classifiedSessions, setClassifiedSessions] = useState<any>({})
    const [sortedDates, setSortedDates] = useState<any>([])
    const [activeTab, setActiveTab] = useState("Sessões")

    const classifySessions = (sessions: any) => {
        const classifiedSessions: Record<string, any[]> = {}

        sessions.forEach((session: any) => {
            const sessionMonth = dayjs(session["session_time"], "DD/MM/YYYY - HH:mm").format("MMMM/YYYY")

            if (sessionMonth in classifiedSessions) {
                classifiedSessions[sessionMonth].push(session)
            } else {
                classifiedSessions[sessionMonth] = [session]
            }
        })

        const sortedDates = Object.keys(classifiedSessions).sort((a, b) =>
            dayjs(a, "MMMM/YYYY").isBefore(dayjs(b, "MMMM/YYYY")) ? 1 : -1
        );

        Object.values(classifiedSessions).map(classifiedSession => sortSessions(classifiedSession))

        return [classifiedSessions, sortedDates]
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    const fetchData = () => {
        getAthleteSessions()
            .then(({ data }) => {
                const [classifiedSessions, sortedDates] = classifySessions(data.sessions)

                setClassifiedSessions(classifiedSessions)
                setSortedDates(sortedDates)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (activeTab === "Sessões") {
            fetchData()
        }
    }, [activeTab])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainLayout hasBackButton={false}>
            <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ "& .MuiTabs-indicator": { top: 0, bottom: "unset" } }}>
                {/* <Tab label="Feed" value={"Feed"} /> */}
                <Tab label="Sessões" value={"Sessões"} />
                <Tab label="Favoritas" value={"Favoritas"} />
            </Tabs>
            {/* <CustomTabPanel value={activeTab} index={"Feed"}>
                <FeedGallery sessions={classifiedSessions} dates={sortedDates} />
            </CustomTabPanel> */}
            <CustomTabPanel value={activeTab} index={"Sessões"}>
                {sortedDates.length > 0 ?
                    sortedDates.map((date: any) => (
                        <SessionList key={date} sessionList={{ date, sessions: classifiedSessions[date] }} />
                    ))
                    : <GallerySkeleton />
                }
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={"Favoritas"}>
                <FavoritesGallery />
            </CustomTabPanel>
        </MainLayout>
    );
}

export default MainPage