import { Flex, Skeleton } from 'antd'

const GalerySkeleton = () => {
    return (
        <>
            <Flex style={{ width: '100px', position: "relative", justifyContent: "space-between" }} vertical>
                <Skeleton.Image active width="100%" style={{ height: "120px" }} />
            </Flex>
            <Flex style={{ width: '100px', position: "relative", justifyContent: "space-between" }} vertical>
                <Skeleton.Image active width="100%" style={{ height: "120px" }} />
            </Flex>
            <Flex style={{ width: '100px', position: "relative", justifyContent: "space-between" }} vertical>
                <Skeleton.Image active width="100%" style={{ height: "120px" }} />
            </Flex>
            <Flex style={{ width: '100px', position: "relative", justifyContent: "space-between" }} vertical>
                <Skeleton.Image active width="100%" style={{ height: "120px" }} />
            </Flex>
            <Flex style={{ width: '100px', position: "relative", justifyContent: "space-between" }} vertical>
                <Skeleton.Image active width="100%" style={{ height: "120px" }} />
            </Flex>
        </>
    )
}

export default GalerySkeleton