export default function range(start, end, step = 1, exclude = []) {
    // Se apenas um argumento for passado, assume-se que é o `end`
    if (end === undefined) {
        end = start;
        start = 0;
    }

    // Validação de argumentos
    if (step === 0) {
        throw new Error("Step cannot be zero.");
    }

    const result = [];
    if (step > 0) {
        for (let i = start; i < end; i += step) {
            if (!exclude.includes(i)) { // Verifica se o número deve ser excluído
                result.push(i);
            }
        }
    } else if (step < 0) {
        for (let i = start; i > end; i += step) {
            if (!exclude.includes(i)) { // Verifica se o número deve ser excluído
                result.push(i);
            }
        }
    }
    return result;
}