import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import MainRouter from './router';

const ClientApp = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <MainRouter />
        </ThemeProvider >
    )
}

export default ClientApp