import React, { useEffect, useState } from 'react';
import { Flex, Select, Typography } from 'antd';

const MoveSelectedTo = ({ options, handleChange }) => {
    const [selectedOption, setSelectedOption] = useState()

    const onChange = (value, selectedOption) => {
        setSelectedOption(value)

        handleChange(selectedOption)

        setTimeout(() => {
            setSelectedOption()
        }, 200)
    }

    return (
        <Flex align="center" style={{ padding: "24px 0 0" }}>
            <Typography.Title level={5} style={{ margin: 0 }}>Mover documentos selecionados para: </Typography.Title>
            <Select
                placeholder="Selecione uma pasta destino"
                style={{
                    width: 240,
                    marginLeft: "16px"
                }}
                options={options}
                onChange={onChange}
                value={selectedOption}
            />
        </Flex>
    )
};
export default MoveSelectedTo;