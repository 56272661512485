import JSZip from "jszip";
import { saveAs } from "file-saver";

// Função auxiliar para fazer o download de um arquivo e retornar o blob e o nome do arquivo
const downloadFile = async (url, index) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Erro ao baixar o arquivo: ${response.statusText}`);
        }
        const blob = await response.blob();
        const fileName = url.split('/').pop().split('?')[0] || `file${index}`;
        return { blob, fileName };
    } catch (error) {
        console.error(`Erro ao baixar o arquivo da URL ${url}:`, error);
        return null;
    }
};

// Função para baixar e zipar arquivos
export const downloadAndZipFiles = async (fileUrls, zipFileName) => {
    const zip = new JSZip();

    // Faz o download de cada arquivo e adiciona ao ZIP
    const downloadPromises = fileUrls.map(async (url, index) => {
        const fileData = await downloadFile(url, index);
        if (fileData) {
            zip.file(fileData.fileName, fileData.blob);
        }
    });

    await Promise.all(downloadPromises);

    // Gera o ZIP e baixa o arquivo
    zip.generateAsync({ type: "blob" }).then(content => {
        saveAs(content, `${zipFileName}.zip`);
    });
};

export const downloadFilesSeparately = async (fileUrls) => {
    const hiddenContainer = document.createElement('div');
    hiddenContainer.style.display = 'none';
    document.body.appendChild(hiddenContainer);

    try {
        for (const [index, url] of fileUrls.entries()) {
            try {
                // 1. Fetch com tratamento CORS
                const response = await fetch(url, {
                    mode: 'cors',
                    cache: 'no-store',
                });

                if (!response.ok) throw new Error(`HTTP ${response.status}`);

                // 2. Obter blob e nome do arquivo
                const blob = await response.blob();
                const fileName = getFileNameFromUrl(url, index, response);

                // 3. Tentar usar Web Share API se for arquivo compartilhável (imagens/PDFs)
                if (isShareableFile(fileName) && navigator.canShare) {
                    const file = new File([blob], fileName, { type: blob.type });

                    try {
                        if (navigator.canShare({ files: [file] })) {
                            await navigator.share({
                                files: [file],
                                title: `Baixar ${fileName}`,
                            });
                            console.log(`Arquivo compartilhado: ${fileName}`);
                            continue; // Pula para o próximo se compartilhado
                        }
                    } catch (shareError) {
                        console.log('Web Share API falhou, usando fallback:', shareError);
                    }
                }

                // 4. Fallback para download tradicional
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                hiddenContainer.appendChild(link);
                link.click();

                // 5. Limpeza e delay
                await new Promise(resolve => setTimeout(resolve, 500));
                URL.revokeObjectURL(link.href);
                link.remove();

            } catch (error) {
                console.error(`Erro no arquivo ${url}:`, error);
                continue;
            }
        }
    } finally {
        hiddenContainer.remove();
    }
};

// Função auxiliar melhorada
function getFileNameFromUrl(url, index, response = null) {
    // 1. Tenta obter do header Content-Disposition (se response estiver disponível)
    if (response) {
        const contentDisposition = response.headers.get('content-disposition');
        if (contentDisposition) {
            const match = contentDisposition.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?/i) ||
                contentDisposition.match(/filename=['"]?([^'"]+)['"]?/i);
            if (match && match[1]) {
                return decodeURIComponent(match[1].trim());
            }
        }
    }

    // 2. Extrai da URL como fallback
    const urlPart = url.split('?')[0]; // Remove query params
    const fileName = urlPart.split('/').pop(); // Pega a última parte

    // 3. Remove caracteres inválidos e adiciona extensão se faltar
    const cleanName = fileName.replace(/[^\w.-]/g, '_');

    // 4. Fallback final se o nome ainda estiver vazio
    return cleanName || `file-${index + 1}`;
}

function isShareableFile(filename) {
    const shareableTypes = [
        'image/jpeg', 'image/png', 'image/gif', 'image/webp',
        'application/pdf', 'text/plain'
    ];
    const extension = filename.split('.').pop().toLowerCase();
    return shareableTypes.includes(extension);
}