import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import ClientApp from "./features/Client";
import BackofficeApp from "./features/Backoffice";
import LoginPage from "./features/Login/pages";
import NewPasswordPage from "./features/Login/pages/new-password";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/client/*" element={<ClientApp />} />
                <Route path="/backoffice/*" element={<BackofficeApp />} />
                <Route path="/" element={<LoginPage />} />
                <Route path="/new-password/:accountHash" element={<NewPasswordPage />} />
                <Route path="/create-account/:accountHash" element={<NewPasswordPage />} />
            </Routes>
        </Router>
    )
}

export default AppRouter;