import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { login, forgotPassword, validateAccessToken } from "../../../utils/api";
import { isAccessTokenValid } from "../../../utils/api-config";
import { saveData, getData } from "../../../utils/local-storage";
import { Layout, Flex, Card, Button, Input, Typography, notification } from "antd";
import './login.scss'

const LoginPage = () => {
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [status, setStatus] = useState("")
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const { handleSubmit } = useForm({
        defaultValues: {
            email: "",
            password: ""
        },
    })

    useEffect(() => { // Check login
        const authData = getData('auth_data');
        const isTokenAuthorized = isAccessTokenValid(authData?.access_token);

        if (isTokenAuthorized) {
            async function validate() {
                await validateAccessToken();
            }

            validate();
        }

        if (isTokenAuthorized) navigate("/client"); // vai ser redirecionado corretamente na rota dependendo do tipo do usuario
    }, [])

    const openNotification = (type, message, description) => {
        api[type]({
            message,
            description,
            placement: 'bottomRight'
        });
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setStatus('');
        // Atualiza o estado correspondente (setEmail ou setPassword)
        eval(`set${capitalize(name)}`)(value);
    }

    const handleSubmitForm = async () => {
        if (email == "" || password == "") {
            openNotification('error', 'Erro', 'Os campos de e-mail e senha são obrigatórios.');
            setStatus('error')
            return
        }

        try {
            setLoading(true);
            const { data } = await login(email, password)
            saveData('auth_data', data)
            openNotification('success', 'Sucesso', 'Login realizado com sucesso. Você será redirecionado para o sistema.')
            setTimeout(() => {
                if (data.user_type === "admin") {
                    navigate('/backoffice')
                } else {
                    navigate('/client')
                }
            }, 2000)
        } catch (e) {
            setLoading(false);
            openNotification('error', 'Não Autorizado', 'Email ou senha informados são inválidos.')
            setStatus('error')
        }
    }

    const handleForgotPasswordSubmit = async () => {
        if (email === "") {
            openNotification('error', 'Erro', 'Insira um e-mail válido.');
            setStatus('error')
            return
        }

        try {
            setLoading(true);
            const response = await forgotPassword(email)
            openNotification('success', 'Sucesso', 'Um e-mail de redefinição de senha foi enviado para seu endereço cadastrado. Verifique sua caixa de entrada e siga as instruções.');
            setTimeout(() => {
                setIsForgotPassword(false);
                setLoading(false);
            }, 2000)
        } catch (e) {
            setLoading(false);
            openNotification('error', 'Erro', 'Não foi possível enviar o email de recuperação.');
        }
    }

    const handleForgotPasswordButton = (status) => {
        setIsForgotPassword(status);
        setStatus('');
    }

    return (
        <Layout className="layout">
            <Flex align="center" justify="center" className="logo-wrapper">
                <img src="/cisp1.svg" className="cisp1-login-logo" alt="Logo CISP1" />
                <img src="/paulobarcellos.svg" className="paulo-barcellos-login-logo" alt="Logo Paulo Barcellos" />
            </Flex>
            <Card bordered={false} className="card-login">
                <form onSubmit={handleSubmit(isForgotPassword ? handleForgotPasswordSubmit : handleSubmitForm)}>
                    <Flex className="title-wrapper" justify="center">
                        <Typography.Title level={2}>
                            {isForgotPassword ? "Recuperar Senha" : "Login"}
                        </Typography.Title>
                    </Flex>
                    <Flex vertical gap="middle">
                        <Input
                            placeholder="E-mail"
                            size="large"
                            className="input"
                            name="email"
                            onChange={handleInputChange}
                            status={status}
                            type="email"
                        />
                        {!isForgotPassword && (
                            <Input.Password
                                placeholder="Senha"
                                size="large"
                                name="password"
                                className="input ant-input"
                                onChange={handleInputChange}
                                status={status}
                            />
                        )}
                        {!isForgotPassword && (
                            <Typography.Text
                                className="forgot-password-link"
                                onClick={() => handleForgotPasswordButton(true)}
                            >
                                <a>Esqueci minha senha</a>
                            </Typography.Text>
                        )}
                    </Flex>
                    <Flex className="button-wrapper" vertical gap="small">
                        <Button
                            type="primary"
                            size="large"
                            className="button"
                            htmlType="submit"
                            loading={loading}
                            iconPosition="end"
                        >
                            {isForgotPassword ? (loading ? "Enviando" : "Enviar") : (loading ? "Entrando" : "Entrar")}
                        </Button>
                        {isForgotPassword && (
                            <Button
                                type="default"
                                size="large"
                                className="button"
                                onClick={() => handleForgotPasswordButton(false)}
                            >
                                Voltar
                            </Button>
                        )}
                    </Flex>
                </form>
            </Card>
            {contextHolder}
        </Layout>
    )
}

export default LoginPage;
