import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, useMediaQuery, useTheme } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import { useLayoutEffect, useRef, useState } from "react";

interface SessionListProps {
    sessionList: any;
}

const SessionList: React.FC<SessionListProps> = ({ sessionList }) => {
    const navigate = useNavigate()
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const theme = useTheme()
    const [rowHeight, setRowHeight] = useState<number>(200); // Estado para o rowHeight
    const columnNumber = mdUp ? 4 : 2
    const emptyImages = calculateEmptyImages()
    const imageListRef = useRef<HTMLUListElement>(null); // Ref para o contêiner do ImageList

    function calculateEmptyImages() {
        const cols = columnNumber;
        const remainder = sessionList.sessions.length % cols;

        return remainder === 0 ? 0 : cols - remainder
    }

    const calculateRowHeight = () => {
        if (imageListRef.current) {
            const containerWidth = imageListRef.current.clientWidth; // Largura do contêiner
            const itemWidth = containerWidth / columnNumber; // Largura de cada ImageListItem
            setRowHeight(itemWidth * 1.54); // 154% da largura
        }
    };

    useLayoutEffect(() => {
        calculateRowHeight(); // Calcula o rowHeight inicial

        window.addEventListener("resize", () => calculateRowHeight); // Recalcula ao redimensionar a janela
        return () => window.removeEventListener("resize", calculateRowHeight); // Limpa o listener
    }, [sessionList])

    return (
        <ImageList cols={columnNumber} rowHeight={rowHeight} ref={imageListRef} sx={{ mb: theme.spacing(10) }}>
            <ImageListItem key="Subheader" cols={columnNumber} sx={{ height: "auto!important" }}>
                <ListSubheader component="div" sx={{ paddingLeft: 1, paddingBottom: 1, color: theme.palette.grey[700], textTransform: "capitalize", fontSize: "18px" }}>{sessionList.date}</ListSubheader>
            </ImageListItem>
            {sessionList.sessions.map((session: any) => (
                <ImageListItem key={session.session_id}
                    onClick={() => navigate(`/client/session/${session.session_id}`)}
                    sx={{
                        overflow: "hidden", // Remove barras de rolagem
                        position: "relative", // Para posicionar a ImageListItemBar corretamente
                    }}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden", // Remove barras de rolagem da imagem
                            cursor: "pointer",
                            "& img": {
                                width: "100%",
                                height: "100%",
                                objectFit: "cover", // Faz a imagem cobrir todo o espaço
                                transition: "transform 0.3s ease-in-out",
                            },
                            "&:hover img": {
                                transform: "scale(1.1)", // Escala apenas a imagem
                            },
                        }}
                    >
                        <img
                            src={session.collection_thumb}
                            alt={`${session.session_time} - ${session.level}`}
                            loading="lazy"
                        />
                    </Box>
                    <ImageListItemBar
                        title={session.session_time}
                        subtitle={session.level}
                        sx={{
                            "& .MuiImageListItemBar-title": {
                                fontSize: { xs: "0.6rem", md: "0.7rem" },
                            },
                            "& .MuiImageListItemBar-subtitle": {
                                fontSize: { xs: "0.6rem", md: "0.7rem" },
                            },
                        }}
                    />
                </ImageListItem>
            ))}
            {Array.from({ length: emptyImages }).map((_, index) => (
                <ImageListItem key={`empty-${index}`}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",

                        }}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

export default SessionList;