import React, { useState } from 'react'
import { Card, Flex, Typography, Button, Divider } from 'antd'
import { useNavigate } from "react-router-dom";
import DragAndDrop from '../DragAndDrop/DragAndDrop'
import { getData } from "../../../../utils/local-storage";

import './files-card.scss'

const FilesCard = ({ selectedFiles, addFile, removeFile, setSelectedFiles, sessionId, checkUploadingDone, isUploadDone }) => {
    const [isSubmiting, setIsSubmiting] = useState(false)
    const navigate = useNavigate()
    const userEmail = getData('auth_data').email

    const handleSubmit = async () => {
        setIsSubmiting(true)
        navigate(`/backoffice/select-athletes/${sessionId}`)
    }

    return (
        <>
            <Card className="files-card" bordered={false}>
                <div style={{ margin: '1%', marginBottom: "50px" }}>
                    <Flex className="title-wrapper" justify="center">
                        <Typography.Title level={2}>Upload</Typography.Title>
                    </Flex>
                    <Card
                        style={{ margin: 'auto' }}
                    >
                        <DragAndDrop
                            addFile={addFile}
                            removeFile={removeFile}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            sessionId={sessionId}
                            checkUploadingDone={checkUploadingDone}
                        />
                    </Card>
                </div>
                <Flex justify="center">
                    <Button type="primary" size="large" loading={isSubmiting} disabled={isUploadDone} onClick={handleSubmit}>Continuar</Button>
                </Flex>
                <Divider />
                <Typography.Text>Após o processamento, o link do resultado será enviado para: <Typography.Text strong>{userEmail}</Typography.Text></Typography.Text>
            </Card>
        </>
    )
}

export default FilesCard