import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Card, Button, notification, Divider, Input, Avatar, List, Popconfirm, Modal } from "antd";
import VirtualList from 'rc-virtual-list';
import { UserAddOutlined, QuestionCircleOutlined, DeleteOutlined, SendOutlined, ExclamationCircleOutlined, WhatsAppOutlined, LinkOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';
import { getInputUserList, deleteInputUser, sendAthleteAccountNotification } from "../../../../utils/api";
import { formatPhoneNumber } from "../../../../utils/formattingText";
import Header from "../../components/Header";
import './athlete.scss'

const AthletePage = () => {
    const [api, contextHolder] = notification.useNotification();
    const [listPage, setListPage] = useState(1);
    const perPage = 10
    const [emptyList, setEmptyList] = useState(false);
    const [searchText, setSearchText] = useState('');
    const ContainerHeight = 400;
    const [data, setData] = useState([]);
    const { Search } = Input;
    const [isSearching, setIsSearching] = useState(false);
    const isMobile = window.innerWidth < 800

    const registerButton = <Button type="primary" href={`/backoffice/athletes/register`}>Cadastrar <UserAddOutlined /></Button>

    const onSearch = async (value, _e, info) => {
        // if (info?.source == 'clear')
        //     return

        setSearchText(value);
        setData([]);
        setEmptyList(false);
        setIsSearching(true);

        try {
            const params = {
                search: value.trim(),
                page: 1,
                perPage: perPage
            };

            const listAthletes = await getInputUserList(params)
            setData(listAthletes.data.users);

            if (listAthletes.data.users.length < perPage) {
                setEmptyList(true);
                setListPage(1);
                return;
            }

            setListPage(2);
            setIsSearching(false);
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível filtrar a lista de atletas.')
        }
    }

    const appendData = async () => {
        if (emptyList || isSearching)
            return;

        try {
            const params = {
                search: searchText.trim(),
                page: listPage,
                perPage: perPage
            };

            const listAthletes = await getInputUserList(params)
            setData(data.concat(listAthletes.data.users));

            if (listAthletes.data.users.length < perPage) {
                setEmptyList(true);
                return;
            }

            setListPage(listPage + 1)
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível carregar a lista de atletas.')
        }
    };

    useEffect(() => {
        appendData();
    }, []);

    const onScroll = (e) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
            appendData();
        }
    };

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
            placement: 'bottomRight'
        });
    };

    const handleRemoveAthlete = async (athleteId) => {
        try {
            const response = await deleteInputUser(athleteId)

            const params = {
                search: '',
                page: 1,
                perPage: perPage
            };

            const listAthletes = await getInputUserList(params)
            setData(listAthletes.data.users);
            openNotification('success', 'Sucesso', 'O atleta foi removido com sucesso.')

            if (listAthletes.data.users.length < perPage) {
                setEmptyList(true);
                setListPage(1);
                return;
            }

            setEmptyList(false);
            setListPage(2);
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível remover o atleta.')
        }
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [link, setLink] = useState('');
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [whatsLoading, setWhatsLoading] = useState(false);
    const [linkLoading, setLinkLoading] = useState(false);

    const handleAthleteAccountNotification = async (athleteId, method = 'email') => {
        setLoading(true);
        setLink('');

        if (method === 'email') setEmailLoading(true);
        if (method === 'whatsapp') setWhatsLoading(true);
        if (method === 'link') setLinkLoading(true);

        try {
            const response = await sendAthleteAccountNotification(athleteId, { method: method });

            if (method === 'email') {
                openNotification('success', 'Sucesso', 'Um link de acesso foi enviado para o e-mail cadastrado.');
            } else {
                setLink(response.data.link);

                if (method === 'link') {
                    setModalVisible(true);
                } else {
                    const whatsappMessage = `Olá! Para criar sua conta em nosso sistema, acesse o link: ${response.data.link}`
                    const whatsappLink = `https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=55${response.data.cellphone}&text=${whatsappMessage}`
                    window.open(whatsappLink, '_blank');
                }
            }
        } catch (e) {
            openNotification('error', 'Erro', 'Não foi possível enviar a notificação ou gerar o link de acesso.');
        }

        setLoading(false);
        setEmailLoading(false);
        setWhatsLoading(false);
        setLinkLoading(false);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(link).then(() => {
            setCopied(true);
        });

        setTimeout(() => {
            setCopied(false);
        }, 2500)
    };

    return (
        <Layout className="layout">
            <Header />
            <Card bordered={true} title="Atletas" className="card" extra={registerButton} style={{ width: '80%' }}>
                <Search
                    placeholder="Procure por nome ou e-mail"
                    allowClear
                    onSearch={onSearch}
                />
                <Divider />
                <List>
                    <VirtualList
                        data={data}
                        height={ContainerHeight}
                        itemHeight={47}
                        itemKey="id"
                        onScroll={onScroll}
                    >
                        {(item) => (
                            <List.Item
                                key={item.email}
                                actions={[
                                    <a href={`/backoffice/athletes/${item.id}`}>Editar</a>,
                                    <Popconfirm
                                        title="Criar conta de acesso"
                                        description={
                                            <div>
                                                <p>Para criar uma conta de acesso ao sistema, escolha uma das opções abaixo</p>
                                                <Button
                                                    icon={<MailOutlined />}
                                                    onClick={() => handleAthleteAccountNotification(item.id, 'email')}
                                                    style={{ marginRight: 8 }}
                                                    title="Notificar via E-mail"
                                                    disabled={loading}
                                                    loading={emailLoading}
                                                >
                                                </Button>
                                                <Button
                                                    icon={<WhatsAppOutlined />}
                                                    onClick={() => handleAthleteAccountNotification(item.id, 'whatsapp')}
                                                    style={{ marginRight: 8 }}
                                                    title="Notificar via WhatsApp"
                                                    disabled={loading}
                                                    loading={whatsLoading}
                                                >
                                                </Button>
                                                <Button
                                                    icon={<LinkOutlined />}
                                                    onClick={() => handleAthleteAccountNotification(item.id, 'link')}
                                                    title="Gerar Link de Acesso"
                                                    disabled={loading}
                                                    loading={linkLoading}
                                                >
                                                </Button>
                                            </div>
                                        }
                                        okButtonProps={{ style: { display: 'none' } }}
                                        cancelText="Cancelar"
                                        icon={<ExclamationCircleOutlined style={{ color: 'green' }} />}
                                    >
                                        <Button icon={<SendOutlined />} />
                                    </Popconfirm>,
                                    <Popconfirm
                                        title="Remover Atleta"
                                        description="Você tem certeza que deseja remover este atleta?"
                                        okText="Sim"
                                        cancelText="Não"
                                        onConfirm={(e) => handleRemoveAthlete(item.id)}
                                        icon={
                                            <QuestionCircleOutlined
                                                style={{
                                                    color: 'red',
                                                }}
                                            />
                                        }
                                    >
                                        <Button icon={<DeleteOutlined />} danger></Button>
                                    </Popconfirm>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={item.image_link} />}
                                    title={<a href={`/backoffice/athletes/${item.id}`}>{item.name}</a>}
                                    description={
                                        <div>
                                            {formatPhoneNumber(item.cellphone)} <br />
                                            {item.email}
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    </VirtualList>
                </List>
            </Card>
            <Modal
                visible={modalVisible}
                title="Link de Acesso"
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <p>Copie o link abaixo:</p>
                <input
                    type="text"
                    readOnly
                    value={link !== '' ? link : <LoadingOutlined />}
                    style={{ width: '100%', marginBottom: 16 }}
                />
                <Button variant="outlined" color={copied ? 'green' : 'default'} onClick={copyLink} disabled={linkLoading}>
                    {copied ? 'Link Copiado' : 'Copiar Link'}
                </Button>
            </Modal>
            {contextHolder}
        </Layout >
    )
}

export default AthletePage