import React, { useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { validateAccessToken } from "../../../../utils/api";
import { isAccessTokenValid } from "../../../../utils/api-config";
import { getData } from "../../../../utils/local-storage";
import UserType from "../../enums/userTypeEnum"

const AuthWrapper = ({ children, requiredUserType }) => {
    const authData = getData('auth_data');
    const isAuthorized = authData && authData.user_type && requiredUserType.includes(authData.user_type);
    const isAdmin = authData && authData.user_type && authData.user_type !== UserType.UserTypeEnum.ATHLETE;
    const isTokenAuthorized = isAccessTokenValid(authData?.access_token);

    useEffect(() => {
        if (isAuthorized && isTokenAuthorized) {
            async function validate() {
                await validateAccessToken();
            }

            validate();
        }
    }, [isAuthorized, isTokenAuthorized]);

    if (isAuthorized && isTokenAuthorized) {
        return children;
    } else {
        return <Navigate to={isTokenAuthorized ? (isAdmin ? "/backoffice" : "/client") : "/"} />;
    }
};

export default AuthWrapper;
