import React, { useState } from "react";
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom";
import { login, newPassword } from "../../../utils/api";
import { Layout, Flex, Card, Button, Input, Typography, notification } from "antd";
import { saveData } from "../../../utils/local-storage";
import './login.scss'

const NewPasswordPage = () => {
    const { accountHash } = useParams();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);

    const { handleSubmit } = useForm({
        defaultValues: {
            password: "",
            confirmPassword: ""
        },
    });

    const openNotification = (type, message, description) => {
        api[type]({
            message,
            description,
            placement: 'bottomRight'
        });
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;
        setStatus("");
        // Atualiza o estado correspondente (setPassword ou setConfirmPassword)
        eval(`set${capitalize(name)}`)(value);
    };

    // Função para redefinir a senha; após sucesso, chama a função de login (handleSubmitForm)
    const handleResetPasswordSubmit = async () => {
        if (password === "" || confirmPassword === "") {
            openNotification('error', 'Erro', 'Os campos de senha são obrigatórios.');
            setStatus('error');
            return;
        }

        if (password !== confirmPassword) {
            openNotification('error', 'Erro', 'As senhas não coincidem.');
            setStatus('error');
            return;
        }

        try {
            setLoading(true);
            const { data } = await newPassword({
                'password': password,
                'passwordConfirmation': confirmPassword,
                'accountHash': accountHash
            });

            saveData('auth_data', data);
            openNotification('success', 'Sucesso', 'Senha redefinida com sucesso. Você será redirecionado para o sistema.');
            setTimeout(() => {
                if (data.user_type === "admin") {
                    navigate('/backoffice');
                } else {
                    navigate('/client');
                }
            }, 2000);
        } catch (e) {
            setLoading(false);
            openNotification('error', 'Erro', 'Não foi possível redefinir a senha.');
            setStatus('error');
        }
    };

    return (
        <Layout className="layout">
            <Flex align="center" justify="center" className="logo-wrapper">
                <img src="/cisp1.svg" className="cisp1-login-logo" alt="Logo CISP1" />
                <img src="/paulobarcellos.svg" className="paulo-barcellos-login-logo" alt="Logo Paulo Barcellos" />
            </Flex>
            <Card bordered={false} className="card-login">
                <form onSubmit={handleSubmit(handleResetPasswordSubmit)}>
                    <Flex className="title-wrapper" justify="center">
                        <Typography.Title level={2}>
                            Nova Senha
                        </Typography.Title>
                    </Flex>
                    <Flex vertical gap="middle">
                        <Input.Password
                            placeholder="Senha"
                            size="large"
                            className="input"
                            name="password"
                            onChange={handleInputChange}
                            status={status}
                        />
                        <Input.Password
                            placeholder="Confirmar Senha"
                            size="large"
                            className="input"
                            name="confirmPassword"
                            onChange={handleInputChange}
                            status={status}
                        />
                    </Flex>
                    <Flex className="button-wrapper" vertical gap="small">
                        <Button
                            type="primary"
                            size="large"
                            className="button"
                            htmlType="submit"
                            loading={loading}
                            iconPosition="end"
                        >
                            {loading ? "Enviando" : "Enviar"}
                        </Button>
                    </Flex>
                </form>
            </Card>
            {contextHolder}
        </Layout>
    );
};

export default NewPasswordPage;
