import { useEffect, useState } from "react";
import Gallery from "../../components/Gallery";
import MainLayout from "../../layouts/Main";
import { getAthleteSessionImages } from "../../../../utils/api";
import { useParams } from "react-router-dom";


const SessionPage: React.FC = () => {
    const { sessionId } = useParams();
    const [session, setSession] = useState([])

    useEffect(() => {
        getAthleteSessionImages(sessionId)
            .then(({ data }) => setSession(data))
            .catch(err => console.log(err))
    }, [sessionId])

    return (
        <MainLayout>
            <Gallery session={session} />
        </MainLayout>
    );
}

export default SessionPage