import { Box, Button, Checkbox, colors, Divider, FormControlLabel, IconButton, ImageList, ImageListItem, ListSubheader, Modal, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder'; // Ícone de estrela vazia
import StarIcon from '@mui/icons-material/Star'; // Ícone de estrela preenchida
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import GallerySkeleton from "../GallerySkeleton";
import { downloadFilesSeparately } from "../../../../utils/download-files";
import sortFiles from "../../../../utils/sort-files";
import { isVideo } from "../../../../utils/checkFileFormat";
import { favoriteImage } from "../../../../utils/api";

interface GalleryProps {
    session: any
}

const Gallery: React.FC<GalleryProps> = ({ session }) => {
    const [images, setImages] = useState<any[]>([]);
    const [emptyImages, setEmptyImages] = useState(0); // Número de espaços vazios necessários
    const [previewMedia, setPreviewMedia] = useState<{ url: string; type: "image" | "video" } | null>(null); // Estado para o preview
    const [rowHeight, setRowHeight] = useState<number>(200); // Estado para o rowHeight
    const [hasSelectedImagesLength, setHasSelectedImagesLength] = useState<boolean>(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const theme = useTheme()
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const columnNumber = mdUp ? 4 : 2
    const imageListRef = useRef<HTMLUListElement>(null); // Ref para o contêiner do ImageList


    // Função para calcular o rowHeight com base na largura do contêiner
    const calculateRowHeight = () => {
        if (imageListRef.current) {
            const containerWidth = imageListRef.current.clientWidth; // Largura do contêiner
            const itemWidth = containerWidth / columnNumber; // Largura de cada ImageListItem
            setRowHeight(itemWidth * 1.54); // 154% da largura
        }
    };

    // Função para abrir o preview
    const handleOpenPreview = (url: string, isVideo: boolean) => {
        setPreviewMedia({ url, type: isVideo ? "video" : "image" });
    };

    const toggleStar = (image_id: string) => {
        const newImages = [...images]
        const foundImage = newImages.find(({ id }) => id === image_id)

        favoriteImage({ image_id: foundImage.id, status: !foundImage.favorite })
            .then(() => {
                foundImage.favorite = !foundImage.favorite
                setImages(newImages)
            })
            .catch(err => console.log(err))
    };

    // Função para fechar o preview
    const handleClosePreview = () => {
        setPreviewMedia(null);
    };

    const toggleSelected = (imageId: string) => {
        const newImages = [...images]

        const foundImage = newImages.find(({ id }) => id === imageId)

        if (foundImage) {
            foundImage.checked = !foundImage.checked

            setImages(newImages)
        }
    }

    const getSelectedImagesUrls = () => {
        return images.filter(image => image.checked).map(image => image.image_url)
    }

    const handleSelectAllImagesClick = () => {
        const newImages = [...images]
        const isAllImagesSelected = images.every(image => image.checked)

        if (isAllImagesSelected) {
            newImages.map(image => image.checked = false)
        } else {
            newImages.map(image => image.checked = true)
        }

        setImages(newImages)
    }

    const handleDownloadClick = () => {
        setIsDownloading(true)
        downloadFilesSeparately(getSelectedImagesUrls())
            .catch(err => console.log(err))
            .finally(() => setIsDownloading(false))
    }

    useLayoutEffect(() => {
        calculateRowHeight(); // Calcula o rowHeight inicial
    }, [images])

    useEffect(() => {
        console.log(images)
        const hasSelectedImage = images.some(({ checked }) => checked)

        setHasSelectedImagesLength(hasSelectedImage)
    }, [images])

    // Atualiza o rowHeight quando o componente é montado ou a janela é redimensionada
    useEffect(() => {
        const cols = columnNumber;
        const remainder = images.length % cols;

        if (session?.images?.length) {
            setTimeout(() => {
                const notMovedImages = [...session.images.filter((image: any) => !image.is_moved)]
                const movedImages = [...session.images.filter((image: any) => image.is_moved)]

                const sortedNotMovedImages = notMovedImages.map((image: any) => {
                    const filename = image.image_url.split("?")[0].split("/").toReversed()[0]

                    return ({
                        ...image,
                        checked: false,
                        filename,
                        isVideo: isVideo(filename)
                    })
                }).sort(sortFiles);

                const sortedMovedImages = movedImages.map((image: any) => {
                    const filename = image.image_url.split("?")[0].split("/").toReversed()[0]

                    return ({
                        ...image,
                        checked: false,
                        filename,
                        isVideo: isVideo(filename)
                    })
                }).sort(sortFiles);

                setImages([...sortedNotMovedImages, ...sortedMovedImages])
            }, 300)
            setEmptyImages(remainder === 0 ? 0 : cols - remainder);
        }

        window.addEventListener("resize", () => calculateRowHeight); // Recalcula ao redimensionar a janela
        return () => window.removeEventListener("resize", calculateRowHeight); // Limpa o listener
    }, [session]);

    return (
        <>
            {images.length ?
                <>
                    <ImageList
                        cols={columnNumber}
                        rowHeight={rowHeight} // Define o rowHeight como um número
                        ref={imageListRef} // Ref para o contêiner
                        sx={{ width: "100%" }} // Garante que o ImageList ocupe toda a largura disponível
                    >
                        <ImageListItem key="Subheader" cols={columnNumber} sx={{ height: "auto!important", marginBottom: theme.spacing(2) }}>
                            <Stack mb={1} px={1} direction={{ xs: "column", sm: "row" }} justifyContent={{ xs: "flex-start", sm: "space-between" }} alignItems={{ xs: "flex-start", sm: "center" }}>
                                <ListSubheader component="div" sx={{ paddingLeft: 0, paddingBottom: { xs: theme.spacing(2), md: 0 }, lineHeight: "24px" }}>{`Sessão do dia ${session.session_time} - ${session.level}`}</ListSubheader>
                                {hasSelectedImagesLength ?
                                    <Button variant="contained" size="small" sx={{ height: "30px" }} loading={isDownloading} onClick={handleDownloadClick}>Baixar selecionados</Button>
                                    : null
                                }
                            </Stack>
                            {/* <Divider /> */}
                            <Box my={1} />
                            <FormControlLabel control={<Checkbox
                                sx={{
                                    padding: 0, // Remove o padding interno do Checkbox
                                    paddingLeft: 2.5,
                                    paddingRight: 1,
                                    color: colors.grey[400],
                                    "& .MuiSvgIcon-root": {
                                        width: "22px", // Tamanho do SVG
                                        height: "22px", // Tamanho do SVG
                                        margin: 0, // Remove margens extras,
                                    },
                                    "& .MuiSvgIcon-root path": {
                                        transform: "scale(1.2)", // Aumenta o tamanho do path dentro do SVG
                                        transformOrigin: "center", // Garante que o scaling seja centralizado
                                    },
                                }}
                                onClick={handleSelectAllImagesClick}
                            />} label="Selecionar todas" />

                        </ImageListItem>
                        {images.map(({ id, thumb_url, image_url, checked, isVideo, favorite, video_duration }, index) => (
                            <ImageListItem
                                key={index}
                                sx={{
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        overflow: "hidden",
                                        cursor: "pointer",
                                        "& img, & video": {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            transition: "transform 0.3s ease-in-out",
                                        },
                                        "&:hover img, &:hover video": {
                                            transform: "scale(1.1)",
                                        },
                                    }}
                                    onClick={() => handleOpenPreview(image_url, isVideo)} // Abre o preview ao clicar
                                >
                                    <img
                                        src={thumb_url} // Usa a thumb estática
                                        alt={`Imagem ${id}`}
                                        loading="lazy"
                                        style={{ opacity: checked ? 0.5 : undefined }}
                                    />

                                    {video_duration ?
                                        <Typography variant="body2" sx={{
                                            position: "absolute",
                                            left: 8, // Ajuste a distância da borda direita
                                            bottom: 8, // Ajuste a distância da borda inferior
                                            color: "white",
                                        }}>
                                            {video_duration}
                                        </Typography>
                                        : null}


                                    <Box
                                        sx={{
                                            position: "absolute",
                                            right: 8, // Ajuste a distância da borda direita
                                            bottom: 8, // Ajuste a distância da borda inferior
                                            backgroundColor: "white", // Fundo branco
                                            display: "inline-flex", // Faz o Box se ajustar ao tamanho do Checkbox
                                            alignItems: "center", // Centraliza o Checkbox verticalmente
                                            justifyContent: "center", // Centraliza o Checkbox horizontalmente
                                            borderRadius: "4px", // Bordas levemente arredondadas
                                            width: "20px", // Tamanho fixo para o Box
                                            height: "20px", // Tamanho fixo para o Box
                                        }}
                                    >
                                        <Checkbox
                                            sx={{
                                                padding: 0, // Remove o padding interno do Checkbox
                                                color: "white",
                                                "& .MuiSvgIcon-root": {
                                                    width: "22px", // Tamanho do SVG
                                                    height: "22px", // Tamanho do SVG
                                                    margin: 0, // Remove margens extras,
                                                },
                                                "& .MuiSvgIcon-root path": {
                                                    transform: "scale(1.2)", // Aumenta o tamanho do path dentro do SVG
                                                    transformOrigin: "center", // Garante que o scaling seja centralizado
                                                },
                                            }}
                                            onClick={(event) => {
                                                toggleSelected(id)
                                                event.stopPropagation(); // Impede a propagação do evento de clique
                                            }}
                                            checked={checked}
                                        />
                                    </Box>

                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 4,
                                            top: 4,
                                            borderRadius: "4px",
                                            padding: "4px",
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation(); // Impede a propagação do evento de clique
                                            toggleStar(id); // Alterna o estado da estrela
                                        }}
                                    >
                                        {favorite ? (
                                            <StarIcon fontSize="medium" sx={{ color: "gold" }} /> // Estrela preenchida
                                        ) : (
                                            <StarIcon fontSize="medium" sx={{ color: "white" }} /> // Estrela vazia
                                        )}
                                    </IconButton>
                                </Box>

                                {isVideo ?
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            left: "calc(50% - 20px)",
                                            top: "calc(50% - 20px)",
                                            backgroundColor: "rgb(0 0 0 / 60%)",
                                            border: "1px solid white",
                                            "&:hover": {
                                                backgroundColor: "rgb(0 0 0 / 40%)",
                                            }
                                        }}
                                        onClick={() => handleOpenPreview(image_url, isVideo)} // Abre o preview ao clicar
                                    >
                                        <PlayArrowIcon fontSize="medium" sx={{ margin: "-1px", color: theme.palette.background.paper }} />
                                    </IconButton>
                                    : null
                                }
                            </ImageListItem>
                        ))}
                        {Array.from({ length: emptyImages }).map((_, index) => (
                            <ImageListItem key={`empty-${index}`}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%"
                                    }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList >

                    < Modal
                        open={!!previewMedia} // Abre o modal se previewMedia não for null
                        onClose={handleClosePreview}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: "90%",
                                maxHeight: "90%",
                                outline: "none",
                            }}
                        >
                            {previewMedia?.type === "image" ? (
                                <img
                                    src={previewMedia.url}
                                    alt="Preview"
                                    style={{ width: "100%", height: "auto", maxHeight: "80vh" }}
                                />
                            ) : (
                                <video
                                    src={previewMedia?.url}
                                    controls
                                    autoPlay
                                    style={{ width: "100%", height: "auto", maxHeight: "80vh" }}
                                />
                            )}
                        </Box>
                    </Modal >
                </>
                :
                <GallerySkeleton />
            }
        </>
    );
};

export default Gallery;